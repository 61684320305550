// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnQusunjILBSMr042RS0dK-4KGJB3Wq2c",
  authDomain: "cozumel-17107.firebaseapp.com",
  projectId: "cozumel-17107",
  storageBucket: "cozumel-17107.firebasestorage.app",
  messagingSenderId: "598932308062",
  appId: "1:598932308062:web:1412bd4a53119afee3f4cf",
  measurementId: "G-MMG3F7BE32"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app)
export {  db };
