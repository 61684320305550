import React from 'react';
import { PopupWidget,  PopupButton } from 'react-calendly';
import NewsletterForm from './NewsletterForm';
const HeroSection = () => {
  return (
    <section className="bg-blue-600 text-white h-screen flex flex-col justify-center items-center text-center">
      <h2 className="text-5xl font-bold mb-4">Welcome to Hierophanteion</h2>
      <p className="mb-6">The stars are at your reach.</p>

      <PopupButton
        url="https://calendly.com/hierophant324/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        className="bg-white text-blue-600 py-2 px-4 rounded hover:bg-gray-200 mb-10"
        text="Click here to schedule!"
      />
      <PopupWidget url="https://calendly.com/hierophant324/30min" text="Schedule a Meeting" textColor="#ffffff" color="#319795" rootElement={document.getElementById('root')} /> 
      <div>
        or
        <NewsletterForm />
      </div>
    </section>
  );
};

export default HeroSection;
