import React, { useState } from 'react';

import { db } from '../FirebaseConfig.js'; // Import Firebase auth
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //Storing user id reference in a document to userRefData collection
      const docRef = await addDoc(collection(db, "News_Letter"), {
        email: email,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      alert('Form submitted:', { email });

    } catch (err) {
      alert(`Cannot register, try later: ${err.message}`);
      return; // Return early if there's an error storing credentials
    }
    // Add your form submission logic here, such as sending data to an API
    setSuccess(true);
  };

  return (
    <div className="max-w-md mx-auto p-6 border border-gray-300 rounded-lg shadow-md mt-20">
      <h2 className="text-2xl font-bold mb-4">Subscribe to our Newsletter</h2>
      <form onSubmit={handleSubmit}>

        <div className="mb-4">
          <label htmlFor="email" className="block text-white mb-2">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 text-black rounded"
          />
        </div>
        <button type="submit" className="w-full p-3 bg-blue-500 text-white rounded hover:bg-blue-600">
          Subscribe
        </button>
      </form>
      {success && <p className="mt-4 text-green-500">Thank you for subscribing!</p>}
    </div>
  );
};

export default NewsletterForm;
