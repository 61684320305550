import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between">
        <h1 className="text-xl font-bold">Hierophanteion</h1>
        <ul className="flex space-x-4">
          <li>
            <a href="#home" className="hover:text-gray-400">Home</a>
          </li>
          <li>
            <a href="https://substack.com/@hierophnt" className="hover:text-gray-400">About</a>
          </li>
          <li>
            <a href="https://calendly.com/hierophant324/30min" className="hover:text-gray-400">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
